<template>
    <div>
        <card-header title="Task Details" :subheader="title" icon="fa-tasks"/>

        <card-body>
            <card-list v-if="props.type === 'flights'">
                <flight-list-item
                        :card="card"
                        :flight="flight"
                        v-for="flight in tasks"
                        :active="(child && child.definition.component === 'edit-flight' && parseInt(child.props.flightId) === flight.id)"
                        @click="$openCard('edit-flight', {registrationId: flight.registration_id, flightId: flight.id}, card)"
                />
            </card-list>
            <card-list v-if="props.type === 'reg-addons'">
                <reg-add-on-list-item
                    :card="card"
                    :addon="addon"
                    v-for="addon in tasks"
                    :active="(child && child.definition.component === 'edit-addon') && child.props.addonId === addon.id.toString()"
                    @click="$openCard('edit-addon', {registrationId: addon.registration_id, addonId: addon.id}, card)"
                />
            </card-list>
            <card-list v-if="props.type === 'reg-events'">
                <template v-for="event in tasks">
                    <custom-event-list-item
                            :card="card"
                            :event="event"
                            v-if="event.type === 'custom_event'"
                            :active="(child && child.definition.component === 'edit-custom-event') && child.props.eventId === event.id.toString()"
                            @click="$openCard('edit-custom-event', {registrationId: event.registration_id, dayId: event.day_id, eventId: event.id}, card)"
                    />
                    <reg-event-list-item
                            :card="card"
                            :event="event"
                            v-else-if="event.type === 'override_event'"
                            :active="(child && child.definition.component === 'edit-override-event') && child.props.eventId === event.id.toString()"
                            @click="$openCard('edit-override-event', {registrationId: event.registration_id, dayId: event.day_id, eventId: event.id}, card)"
                    />
                </template>
            </card-list>
            <card-list v-if="props.type === 'trip-events'">
                <event-list-item
                    :card="card"
                    :event="event"
                    v-for="event in tasks"
                    :active="(child && child.definition.component === 'edit-event') && child.props.eventId === event.id.toString()"
                    @click="$openCard('edit-event', {tripId: event.trip_id, dayId: event.day_id, eventId: event.id}, card)"
                />
            </card-list>
            <card-list v-if="props.type === 'trip-addons'">
                <trip-add-on-list-item
                    :card="card"
                    :addon="addon"
                    v-for="addon in tasks"
                    :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                    @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    context
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import {client as http} from "../../http_client";
    import FlightListItem from "../../components/FlightListItem";
    import CardList from "../../TIER/components/CardList";
    import CustomEventListItem from "../../components/CustomEventListItem";
    import RegEventListItem from "../../components/RegEventListItem";
    import RegAddOnListItem from "@/components/RegAddOnListItem";
    import EventListItem from "../../components/EventListItem";
    import TripAddOnListItem from "../../components/TripAddOnListItem";

    export default {
        components: {TripAddOnListItem, EventListItem, RegAddOnListItem, RegEventListItem, CustomEventListItem, CardList, FlightListItem, CardBody, CardHeader},
        props: ['card', 'props', 'child'],
        data: function () {
            return {
                title: '',
                tasks: []
            };
        },
        methods: {
            loadTasks() {
                this.$emit('loading', true);
                http.get('/api/tasks/' + this.props.type, {force: true}).then(response => {
                    this.tasks = response.data;
                    this.$emit('loading', false);
                });
            },
            reload() {
                this.loadTasks();
            }
        },
        watch: {
            'props.type': function() {
                if (this.props.type === 'flights') this.title = 'Registration Flights';
                if (this.props.type === 'reg-events') this.title = 'Registration Events';
                this.loadTasks();
            }
        },
        mounted() {
            if (this.props.type === 'flights') this.title = 'Registration Flights';
            if (this.props.type === 'reg-events') this.title = 'Registration Events';
            this.$emit('title', this.title);
            this.loadTasks();
        }
    };
</script>

<style scoped>

</style>
